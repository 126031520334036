@import 'variable';
@import url(./common/index.less);

#root {
  height: 100vh;
}

.dropdown-padding {
  padding: 0 16px;
  width: 100%;
  .ant-select-arrow {
    inset-inline-end: 25px;
  }

  span.anticon.anticon-down.pointer-events-none {
    pointer-events: none;
  }
}

.wrapper {
  padding: 32px;
  padding-bottom: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  scroll-behavior: smooth;

  @media @screen991 {
    font-size: 14px;
    padding: 24px;
  }

  @media @screen767 {
    padding: 16px;
    padding-bottom: 0;
  }
}

.responsive-table {
  width: 100%;
  overflow: auto;
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding: 0;
      height: auto;
    }
  }
}

.inset-0 {
  inset: 0;
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

.cursor-block {
  cursor: not-allowed;
}

.block {
  display: block;
}

.hidden {
  display: none;
}

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-80 {
  width: 80%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.full-width,
.w-full {
  width: 100%;
}

.full-height,
.h-full {
  height: 100%;
}

//width classes

.site-result-demo-error-icon {
  color: @colorError;
}

//CUSTOM STYLE CLASSES END

.header-wrapper {
  .d-flex;
  .justify-between;
  .align-center;
}

.sticky-action-form {
  .ant-card-head-title {
    @media @screen767 {
      padding: 0;
    }
  }

  .ant-page-header-heading {
    @media @screen767 {
      padding: 0;
      min-height: 48px;
    }
  }
}

.right-border {
  border-right: 1px solid @border-color;
}

.add-edit-form {
  padding: 26px 0;

  .ant-form-item {
    max-width: 488px;

    &.full-width {
      width: 100%;
      max-width: 100%;
    }
  }
}

/*table draggable css start here*/
.row-dragging {
  background: @layout-body-background;
  border: 1px solid @primary-color;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.react-tel-input .invalid-number-message {
  left: 0px;
  top: 35px;
  background: none;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

/*sidebar custom less start here*/

.responsive-logo img {
  width: 44px;
}

.ant-layout-sider.close {
  width: 0;
  max-width: 0;
  min-width: 0 !important;
  left: 0;

  @media @screen767 {
    padding: 0;
  }
}

.ant-layout-sider {
  @media @screen767 {
    width: 0;
    max-width: 0;
    position: fixed !important;
    height: 100%;
    left: -240px;
    z-index: 12;
  }
}

.app-header,
.app-main-sidebar .cr-user-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ant-layout.ant-layout-has-sider {
  height: 100vh;
}

.ant-layout-header {
  @media @screen767 {
    padding: 0 16px !important;
  }
}

.overlay-disable {
  @media @screen767 {
    display: none;
    visibility: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .overlay-responsive.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: @black-color;
    opacity: 0.6;
    z-index: 11;
  }
}

/*sidebar custom less end here*/
.ant-layout-footer {
  @media @screen767 {
    padding: 16px 50px;
  }
}

/* width */
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 8px;
  background-clip: padding-box;
  color: transparent;
  border-left: 0 solid transparent;
  border-radius: 8px;
  border-left: 8px solid rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}

// :hover::-webkit-scrollbar-thumb {
//   border-left: 8px solid rgba(0, 0, 0, 0.3);
//   background-clip: padding-box;
//   -webkit-box-shadow: inset 0 0 0 8px;
//   box-shadow: inset 0 0 0 8px;
// }

/*ant card body scroll class*/
.ant-body-scroll {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
}

.ant-card-small {
  > .ant-card-body {
    .card-body-wrapper {
      padding: 12px;
    }
  }
}

.ant-card-actions > li {
  margin: 12px 0px;
}

/*antbody scroll class end here*/
/*fleg dropdown css start here*/
.react-tel-input .form-control {
  padding: 4px 14px 4px 60px;
  width: 100%;
  position: relative;
  background-color: @white-color;
  border: 1px solid @input-border;
  border-radius: 6px;
  font-size: 14px;
}

/*fleg dropdown css end here*/
.ant-layout .ant-layout-header {
  background-color: @layout-body-background;
}

.ant-layout-sider-children {
  background-color: @layout-body-background;
}

.ant-layout .ant-layout-sider-trigger {
  background-color: @layout-body-background;
  border: 1px solid @border-color;
  border-left: 0;
  color: @black-color;
  border-bottom: 0;

  @media @screen767 {
    display: none;
  }
}

.ant-layout-content.wrapper {
  padding: 0;
}

/* Video module css start here */

.preview-card {
  height: 150px;
  width: 150px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  .lazy-load-image,
  img {
    height: inherit;
    width: inherit;
    object-fit: cover;
    object-position: center;
  }

  & .ant-typography {
    position: absolute;
    bottom: 5px;
    left: calc(150px / 2);
    transform: translateX(-50%);
    width: 90%;
    text-align: center;
    font-weight: 700;
  }

  & .remove-icon {
    background-color: @black-color;
    color: @white-color;
    border: 0;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    cursor: pointer;
  }
}

.selectable-modal {
  &-body {
    max-height: 500px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 8px 0 0;
    overflow-x: hidden;
  }

  &-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-card {
    cursor: pointer;
    transition: border-color 0.3s;

    & .ant-card-cover .label-poster {
      width: 100%;
      height: 200px;
      max-height: 200px;
      overflow: hidden;

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }

    & .checked-icon {
      position: absolute;
      background-color: @green-6;
      right: 5px;
      top: 5px;
      height: 24px;
      width: 24px;
      border-radius: 12px;
      color: @white-color;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;
    }
  }
}

/*ant menu css start here*/
.sidebar {
  .profile-menu {
    .ant-menu-submenu {
      margin-bottom: 0;
      border: 0;

      .ant-menu-submenu-title {
        color: @black-color;
      }
    }
  }

  .ant-menu-submenu {
    &.ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        color: @black-color;
        font-weight: 700;
      }
    }

    .ant-menu-submenu-title {
      justify-content: center;
      padding: 0;
      width: 100%;
      margin-left: 0;
      font-size: 16px;
      color: @black-color;
      height: auto;
      line-height: 110%;
      margin-bottom: 24px;
      font-weight: 500;

      @media @screen1150 {
        font-size: 14px;
        margin-bottom: 30px;
      }

      .ant-menu-title-content {
        flex: inherit;
      }
    }

    .ant-menu-submenu-arrow {
      position: relative;
      margin-left: 8px;
      inset-inline-end: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-menu {
    .ant-menu-sub {
      .ant-menu-item:first-child {
        margin-top: 0;
      }
    }
    .ant-menu-item-divider {
      margin: 24px auto;
    }
    .ant-menu-item {
      margin: 0;
      padding: 0;
      width: 100%;
      justify-content: center;
      color: @black-color;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      height: auto;
      margin: 24px auto;
      flex: initial;

      @media @screen1150 {
        font-size: 14px;
        margin-bottom: 30px;
      }

      .ant-menu-title-content {
        flex: inherit;
      }

      &.ant-menu-item-selected {
        .ant-menu-title-content {
          border-bottom: 6px solid @black-color;
          color: @black-color;
          font-weight: 700;
        }
      }
    }
  }

  .profile-menu {
    .ant-menu-light.ant-menu-root.ant-menu-vertical {
      border: 0;
    }

    .ant-menu {
      .ant-menu-submenu {
        .ant-menu-submenu-title {
          margin: 0;
          padding: 6px 12px;
          width: 100%;
          border-top: 1px solid @border-color;
          border-radius: 0;
          display: flex;
          justify-content: flex-start;

          .ant-menu-submenu-arrow {
            position: absolute;
            inset-inline-end: 16px;
          }
        }
      }
    }
  }
}

.ant-layout-sider-collapsed {
  .sidebar .profile-menu .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
    justify-content: center;
  }
}

/*ant menu css end here*/
/*radio button css start here*/
.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background: @black-light;
  color: @layout-body-background;

  &:not(:where(.css-dev-only-do-not-override-1iwccph).ant-radio-button-wrapper-disabled):hover {
    color: @layout-body-background;
  }
}

/*radio button css end here*/

.page-wrapper {
  display: flex;
  flex: 1;
  &.workspace {
    .page-wrapper-body {
      padding: 0;
      .add-edit-form {
        padding: 26px;
        .ant-form-item {
          max-width: 100%;
        }
        .ant-collapse-borderless {
          background-color: #a5a5a5;
        }
        .ant-collapse-borderless > .ant-collapse-item {
          border-bottom: 1px solid #b6b4b3;
        }
      }
    }
  }
  .page-wrapper-body {
    flex: 1;
    padding: 0 24px;
    width: 100%;
  }

  .preview-bar {
    display: flex;
    flex-direction: column;
    padding: 20px 28px;
    max-width: 36.97%;
    width: 100%;
    border-left: 1px solid @border-color;
  }
}

.page-preview-modal {
  height: 97vh;
  & .ant-modal-content {
    height: 100%;

    .ant-modal-body {
      height: 100%;
    }
  }
}

.preview-frame-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  .loading {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &.mobile {
    max-width: 425px;
    width: 100%;
  }
}

.custom-radio-group {
  label.ant-radio-button-wrapper {
    padding: 0px;
    height: 40px;
    width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      border-start-start-radius: 4px;
      border-end-start-radius: 4px;
    }
    &:last-child {
      border-start-end-radius: 4px;
      border-end-end-radius: 4px;
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-inline-start: 1px solid #4b4441;
    }
  }
}

.cs-card {
  background: @black-light;
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-card-meta-title {
    color: @layout-body-background;
    line-height: 1;

    .asset-title {
      font-weight: 600;
    }
  }

  .ant-card-meta-description {
    color: @layout-body-background;

    div {
      color: @layout-body-background;
      margin: 0;
    }
  }

  .ant-card-body {
    padding: 16px;
  }

  .ant-card-actions {
    background: @black-light;

    .ant-btn {
      color: @layout-body-background;
    }
  }
}

.text-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  padding: 0;

  &:not(:disabled):hover {
    background: transparent;
  }
}

.text-btn2 {
  .text-btn;
  color: rgba(0, 0, 0, 0.45);
}

.listview {
  width: 100%;

  .ant-table-wrapper {
    .ant-table {
      table {
        th {
          padding: 0 16px;
          background: transparent;
          border: 0;
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 110%;
          letter-spacing: -0.02em;
          text-transform: capitalize;

          &::before {
            display: none;
          }
        }

        tbody {
          &.ant-table-tbody {
            td {
              padding: 4px 16px;
              border: 0;
              background: transparent;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 110%;
              letter-spacing: -0.02em;
              border: 0;
            }

            tr {
              &:last-child {
                td {
                  border: 0;
                }
              }
            }
          }

          tr {
            position: relative;

            &:hover {
              .ant-table-cell {
                &:first-child {
                  &::before {
                    content: '';
                    height: 6px;
                    width: 12px;
                    background: @black-color;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.page-header {
  h4 {
    margin: 0;
  }
}

/*ant tag css start here*/
.ant-tag {
  padding: 8px 12px;
  border-radius: 32px;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.09em;
  min-height: 36px;
  max-height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  > span {
    + .anticon {
      margin-inline: 0;
    }
  }

  &.tag-small {
    font-size: 12px;
    min-height: 32px;
    max-height: 32px;
  }

  &.tag-large {
    font-size: 16px;
    min-height: 48px;
    max-height: 48px;
  }
}

.custom-tag {
  min-height: unset !important;
  min-width: unset !important;
  font-size: 10px !important;
  line-height: 10px !important;
  padding: 3px 5px !important;
}

.ant-tag.custom-tag {
  color: @black-color;
  font-weight: 500;
}

/*ant tag css end here*/

/*video card css start here*/
.Video-card {
  min-width: 164px;
  max-width: 164px;

  .video-img {
    background: @tq-neutral-500;
    padding-top: 56%;
    .mb-8;
  }
}

.video-grid {
  .Video-card {
    min-width: 100%;
    max-width: 100%;
  }
}

/*video card css end here*/

/*button css start here*/
.cs-button {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin: 0 8px;
  }

  &.ant-btn.ant-btn-sm {
    height: 40px;
  }

  &.ant-btn.ant-btn-lg {
    height: 56px;
  }
}

/*button css end here*/
.ant-carousel {
  .slick-track {
    display: flex;
  }
}

/*badge css start here*/

/*badge css end here*/

.responsive-grid {
  max-width: 375px;
  width: 100%;
  padding: 0 16px;
  border-inline-end: 1px solid @border-color;
}

.edit-page {
  .templategrid {
    .video-list-grid.responsive-view .videolist {
      padding: 16px 0;
    }
  }

  .module-list {
    width: 100%;
    .ant-typography {
      cursor: pointer;
      position: relative;
      font-weight: 500;
      font-size: 14px;
      line-height: 110%;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      position: relative;

      &::before {
        display: none;
        position: absolute;
        height: 5px;
        width: 12px;
        content: '';
        background-color: @black-color;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
      }

      &:hover {
        &::before {
          display: block;
        }
      }
    }
    .module-collapse {
      width: 100%;
      .ant-collapse-header {
        padding: 8px 0px;
        display: flex;
        align-items: center;
      }
      .ant-collapse-content-box {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        padding: 4px 16px;
      }
      .ant-collapse-expand-icon {
        order: 1;
      }
      .ant-collapse-header-text {
        margin-inline-end: 4px;
      }
    }
  }
}

/*section wrapper css start here*/
.section-wrapper {
  padding-left: 53px;

  .section-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .delete-action {
    position: absolute;
    left: 8px;
    top: 0;

    button {
      padding: 0;
      height: 14px;
      width: 14px;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .section-action {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 7px;

    button {
      padding: 0;
      height: 14px;
      width: 14px;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.section-add-block {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    padding: 0;
    height: 30px;
    width: 30px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dash {
    width: calc(100% - 53px);
    border-top: 3px dashed @black-color;
  }
}

/*section wrapper css end here*/
/*ant switch css start here*/
.ant-switch {
  min-width: 25px;
  width: 25px;
  height: 16px;

  &[aria-checked='false'] {
    .ant-switch-handle {
      &::before {
        background: @layout-body-background;
      }
    }
  }

  .ant-switch-handle {
    height: 12px;
    width: 12px;
  }

  &.ant-switch-checked {
    .ant-switch-handle {
      inset-inline-start: calc(100% - 14px);
    }
  }
}

/*ant switch css end here*/
/*ant checkbox radio button css start here*/
.ant-checkbox-wrapper {
  align-items: center;

  .ant-checkbox {
    top: 0;
  }
}

.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after {
  box-sizing: border-box;

  margin: auto;
  inset-inline-start: 50%;
  /* display: table; */
  width: 100%;
  height: 100%;
  border: 2px solid @layout-body-background;
  /* border-top: 0; */
  transform: rotate(0deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  content: '';
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
}

.ant-checkbox .ant-checkbox-inner:after {
  position: absolute;
  inset-inline-start: 50%;
  top: 50%;
  left: 0;
  right: 50%;
  transform: rotate(0deg) scale(0) translateY(-50%, -50%);
  opacity: 0;
}

/*ant checkbox radion button css end here*/
.layout-wrap {
  margin-right: -24px;

  > div {
    border-right: 1px solid @border-color;

    &:last-child {
      border-right: 0;
    }
  }

  .preview-bar {
    height: calc(100vh - 60px);
    overflow: auto;
  }

  .templategrid {
    max-width: 460px;
    width: 100%;
    padding: 22px 30px 22px 0;
    flex: 1;
    height: calc(100vh - 60px);
    overflow: auto;

    @media @screen991 {
      width: 50%;
    }
  }

  .form-layout-section {
    flex: 1;
    .form-layout {
      padding: 31px 36px;
      height: calc(100vh - 152px);
      overflow: auto;

      .form-group,
      .ant-form-item {
        max-width: 488px;
        width: 100%;
      }
    }
    .module-title {
      padding: 8px 36px;
      font-size: 24px;
    }
  }

  .module-grid {
    max-width: 366px;
    width: 100%;
    flex: 1;
    padding: 24px 33px;

    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 110%;
      letter-spacing: -0.02em;
      text-transform: capitalize;
    }
  }
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: @layout-body-background;
}

/*card css start here*/
.small-card {
  display: flex;

  img {
    flex: 0 0 80px;
    height: 80px;
    width: 80px;
    object-fit: cover;
    overflow: hidden;
  }

  .card-description {
    padding: 16px 16px 16px 12px;
    background: @white-color;
    flex: 1;
    height: 80px;
    overflow: hidden;

    .card-dc {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      max-height: 30px;
      margin: 0 auto;
      font-size: 14px;
      line-height: 1;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 8px;
    }
  }
}

.ant-col {
  .cs-card {
    .cs-card-img {
      position: relative;
      height: 0;
      padding-top: 56%;
      overflow: hidden;
      cursor: pointer;
      background: #b0adab;

      .ant-image {
        position: absolute;
        left: 0;
        top: 0;
      }

      .lazy-load-image,
      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }

    .cs-card-description {
      padding: 16px;
      background: @black-light;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;

      .card-dc {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        max-height: 48px;
        font-size: 14px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;
      }

      .card-button-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 -6px;

        .ant-btn {
          width: calc(50% - 12px);
          margin: 0 6px;
          flex: 1;

          span {
            display: flex;
            align-items: center;
            gap: 8px;
            &.anticon-eye {
              gap: 0;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.featuredcard {
  width: 100%;
  height: 525px;

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  .featuredcard-dec {
    padding: 0 16px 24px 16px;
    .absolute;
    bottom: 0;
    width: 100%;
  }
}

/*card css end here*/
/*card css end here*/
.assets-filter-wrapper {
  .ant-radio-button-wrapper {
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}

.status {
  &.ant-badge {
    sup {
      font-weight: 500;
      color: @status-color;
      border-radius: 3px;
      line-height: 20px;
    }
  }
}

.ant-form-vertical .ant-form-item-label > label {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 110%;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}

div#customDomain_extra {
  white-space: pre-line;
}

.cbtn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-top: 8px;

  button {
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
    /* or 15px */
    height: auto;
    letter-spacing: 0.09em;
    text-transform: uppercase;

    &:not(:disabled):hover {
      background: transparent;
    }
  }
}

.ant-modal-root {
  .ant-modal-mask {
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(5px);
  }

  .ant-modal .ant-modal-content {
    background: @input-border;
    border-radius: 10px;
  }

  .ant-modal .ant-modal-header {
    background: @input-border;
    margin-bottom: 5px;
  }

  .ant-modal .ant-modal-close {
    height: 32px;
    width: 32px;
    background: @black-color;
    border-radius: 50%;
    top: 20px;
    right: 20px;

    .ant-modal-close-x {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      fill: @input-border;
    }
  }
}

.overlay-responsive {
  display: none;

  &.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.6;
    z-index: 11;
  }
}

.ant-input {
  @media @screen1150 {
    height: 40px;
    font-size: 14px;
  }
}

.ant-select,
.ant-select-selector .ant-select-selection-search-input,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  @media @screen1150 {
    height: 40px;
    font-size: 14px;
  }
}

.ant-select-multiple .ant-select-selector {
  @media @screen1150 {
    padding: 0;
  }
}

.ant-select-dropdown .ant-select-item {
  max-width: 400px;
  @media @screen1150 {
    font-size: 14px;
  }
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder,
.ant-select-single .ant-select-selector .ant-select-selection-item {
  @media @screen1150 {
    line-height: 40px;
  }
}

.nested-table {
  table tr td:nth-of-type(1) {
    padding: 0 !important;
  }

  table tr td:nth-of-type(2).ant-table-row-expand-icon-cell {
    padding: 0 !important;
  }

  table tbody tr:hover .ant-table-cell:first-child::before {
    display: none;
  }

  .nested-table--children {
    margin: 5px 0px;
    padding: 15px 0px 15px 30px;
    border-radius: 5px;
    border: 1px solid @menu-item-color;
  }
}

.lazy-load-image {
  position: relative;

  .canvas-wrapper {
    position: absolute;
    inset: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.ant-modal-confirm-btns {
  button {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.09em;
    line-height: 110%;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    background: none;
    box-shadow: none;
  }

  button:nth-child(1) {
    color: rgba(0, 0, 0, 0.45) !important;

    &:hover {
      color: #000 !important;
      border-color: transparent !important;
    }
  }

  button:nth-child(2) {
    color: #000;

    &:hover {
      color: #000 !important;
      background-color: transparent !important;
      // border-color: transparent !important;
    }
  }
}

.avatar {
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @tq-blue-2;
  border-radius: 999px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .initials {
    font-weight: 600;
    text-transform: uppercase;
  }
}

.ant-picker {
  height: 50px;
  margin: 0;
  padding: 9.1px 11px;
  position: relative;
  width: 100%;
  min-width: 0;
  height: 100%;
  background-color: #3a3a3a;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #4b4441;
  border-radius: 0;

  & .ant-picker-input input {
    color: #b6b4b3;
    font-size: 19px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: Founders Grotesk Mono;
  }

  & .ant-picker-input input::placeholder {
    color: #b6b4b3;
    opacity: 1;
  }

  & .ant-picker-input input:-ms-input-placeholder {
    color: #b6b4b3;
  }

  & .ant-picker-input input::-ms-input-placeholder {
    color: #b6b4b3;
  }

  .ant-picker-suffix {
    color: #b6b4b3;
  }

  .ant-picker-separator {
    color: #b6b4b3;
  }
}

.react-tel-input {
  height: 50px;

  .form-control {
    margin: 0;
    padding: 9.1px 11px 9.1px 58px;
    color: #b6b4b3;
    font-size: 19px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: Founders Grotesk Mono;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    height: 100%;
    background-color: #3a3a3a;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #4b4441;
    border-radius: 0;

    &::placeholder {
      color: #b6b4b3;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #b6b4b3;
    }

    &::-ms-input-placeholder {
      color: #b6b4b3;
    }
  }

  .flag-dropdown {
    width: 48px;
    border-color: #4b4441;
    background: #3a3a3a;

    .selected-flag {
      border-right: 1px solid #b6b4b3;
      width: inherit !important;
      border-radius: 0 !important;
      background: #3a3a3a !important;

      &:hover {
        background: transparent;
      }
    }

    .country-list {
      background: #3a3a3a;
      color: #b6b4b3;
      z-index: 9999;
      margin-top: 5px;
      margin-bottom: 0px;
      padding: 4px;

      .country {
        padding: 10px 12px;

        .dial-code {
          color: #fff;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.04);
          color: #b6b4b3;
        }

        &.highlight {
          color: #b6b4b3;
          background-color: #8a8581;
        }
      }
    }
  }
}

.collection-card .collection-img,
.collection-hero-img,
.contributorcard .auther-img,
.ctaCard-img,
.featured-collection-img,
.banner {
  .lazy-load-image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
}

.collection-hero,
.heroblock,
.information-block,
.featuredcontributor {
  .container {
    padding: 0 24px;
  }

  .container-rnp {
    padding: 0 24px;
  }
}

.button-section {
  margin-top: 10px;
}

.theme-picker-item {
  height: 119px;
  width: 92px;
  padding: 11px;

  border: 4px solid transparent;
  cursor: pointer;

  .title {
    font-family: 'Sanomat';
    font-size: 33px;
    line-height: 110%;
    color: @black-color;

    &.light {
      color: @white-color;
    }
  }

  .sub-title {
    font-family: 'Founders Grotesk';
    font-size: 16px;
    line-height: 140%;

    &.light {
      color: @white-color;
    }
  }

  .footer {
    height: 22px;
    width: 100%;
    background-color: @tq-footer-bg;
    color: @tq-neutral-0;
    margin-top: 12px;
    padding: 0 8px;
    font-size: 16px;
    line-height: 140%;
    font-weight: 500;
    font-family: 'Founders Grotesk';
  }

  &.active-theme {
    border: 4px solid @tq-neutral-1000;
  }
}

.one-side-carousel .ant-carousel .slick-list {
  padding-left: 0;
}

.ant-input-clear-icon {
  color: #b6b4b3 !important;
}
.quill {
  background-color: #3a3a3a;

  .ql-toolbar {
    border: none !important;
    border-bottom: 1px solid #b6b4b3 !important;

    .ql-formats {
      .ql-active {
        color: #b6b4b3;
        font-weight: 600;
        background-color: #8a8581;
      }
      .ql-picker-label {
        border: none !important;
        color: #b6b4b3 !important;

        &:hover {
          color: #b6b4b3;
        }
      }
      .ql-size {
        .ql-picker-item {
          padding: 10px 12px;
        }
      }
      .ql-picker-options {
        margin-top: 8px;
        left: -8px;
        background-color: #3a3a3a;
        border: none !important;
        padding: 4px;
        .ql-picker-item {
          &:hover {
            color: #b6b4b3;
          }
          &.ql-selected {
            color: #b6b4b3;
            font-weight: 600;
            background-color: #8a8581;
          }
        }
      }
      & > * {
        color: #b6b4b3 !important;
      }
      .ql-stroke {
        stroke: #b6b4b3 !important;
      }
      .ql-fill {
        fill: #b6b4b3 !important;
      }
    }
  }
  .ql-container {
    border: none !important;
    .ql-editor {
      color: #b6b4b3;
      padding: 12px 16px;
      min-height: 130px;
    }
    .ql-tooltip {
      left: 14px !important;
      box-shadow: none;
      top: 20px;
      background-color: #3a3a3a;
      color: #b6b4b3 !important;
      &.ql-editing {
        input[type='text'] {
          background-color: #3a3a3a;
          color: #b6b4b3 !important;
          outline: none;
        }
      }
      a {
        color: #b6b4b3 !important;
      }
    }
  }
}
.video-platform-select-filter {
  max-width: 200px;
  min-width: 200px;
}

.input-divider {
  max-width: 488px;
  margin-bottom: 5px;
  text-align: center;
}
.text-label {
  font-size: 17px;
  font-weight: 500;
  margin: 8px 0;
}
.peertube-section {
  margin-left: 16px;
}

.option-img {
  width: 20px;
  margin-right: 8px;
  object-fit: contain;
}

.white-btn {
  color: @white-color;
  &.ant-btn-text:not(:disabled):hover {
    color: @white-color;
  }
  &.ant-btn-link:not(:disabled):hover {
    color: @white-color;
  }
}

.black-btn {
  color: @black-color;
  &.ant-btn-text:not(:disabled):hover,
  &:not(:disabled):hover {
    color: @black-color;
  }

  &.ant-btn-link:not(:disabled):hover {
    color: @white-color;
  }
}

.display-none {
  display: none;
}

.font-12 {
  font-size: 12px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}

.copy-icon {
  font-size: 16px;
  &.ant-typography {
    margin-bottom: unset;
  }
  .anticon-copy,
  .anticon-check {
    color: @white-color;
  }
  &.black-color {
    .anticon-copy,
    .anticon-check {
      color: @black-color;
    }
  }
}

.mx-8 {
  margin: 0 8px;
}

.profile-name {
  width: 100px;
  &.ant-typography {
    margin-bottom: unset;
  }
}

.select-add-btn {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  font-size: 19px;
  height: 52px;
  color: @white-color;
  &.ant-btn-text:not(:disabled):hover {
    color: @white-color;
  }
}

.table-view-asset {
  width: 54px;
  height: 54px;
}

.table-view-btn {
  padding: 4px 15px !important; //to override antd inline css
}

.drag-icon {
  outline: none;
  width: 16px;
}

// button hover color
.hc-n-500:not(:disabled):hover {
  &.ant-btn {
    color: @tq-neutral-500;
  }
}
.hb-n-500:not(:disabled):hover {
  &.ant-btn {
    border-color: @tq-neutral-500;
  }
}

.full-html {
  height: 100%;
  &.ant-form-item {
    width: 100%;
    max-width: unset;
  }
  .ant-form-item-row {
    height: 100%;
    .ant-form-item-control-input {
      height: 100%;
    }
    .ant-form-item-control-input-content {
      height: 100%;
    }
    @media only screen and (max-width: 575px) {
      display: flex;
      flex-direction: row;
    }
  }
}

.minimum-w-dropdown {
  min-width: 150px;
  max-width: 250px;
}

.community-form {
  .images-wrapper {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;

    .image-wrapper {
      height: 108px;
      width: 72px;
      border-radius: 6px;
      cursor: pointer;
      transition: width 0.3s;
      position: relative;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      &:hover,
      &.active {
        width: 108px;
      }

      & .checked-icon {
        position: absolute;
        background-color: @green-6;
        right: 5px;
        top: 5px;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        color: @white-color;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
      }
    }
  }
}

.title-line-clamp {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  background: @tq-neutral-500;
}

.workspace-configs {
  .config-form-item {
    margin-bottom: 24px;
  }
  .config-item-label {
    font-weight: 400;
    font-size: 17px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    padding-bottom: 8px;
    display: block;
  }
  .community-colors-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
  .color-picker-text {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .community-images-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 8px;
  }

  .font-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.url-div {
  max-width: 488px;
}

.category-card {
  &.cta-btn-bg-black {
    display: inline;
    .cta-btn-image {
      z-index: 1;
    }
    &::before {
      content: '';
      left: 0;
      top: 0;
      background: @tq-neutral-900;
      opacity: 0.28;
      height: 100%;
      z-index: 1;
      width: 100%;
    }
    &:hover {
      .topic {
        color: @tq-neutral-0;
      }
    }
    .topic {
      color: @tq-neutral-0;
      z-index: 2;
    }
  }
}

//form
.form-card-slider {
  width: 100%;
  display: block;
  .one-side-carousel {
    width: 100%;
    .slick-slide {
      margin: 0 8px;
      @media @screen375 {
        margin: 0 4px;
      }
    }
  }
}
.form-card {
  max-width: 360px;
  min-width: 300px;
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  @media @screen375 {
    max-width: 280px;
    min-width: 280px;
  }
  .fc-title {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 8px;
  }
  .form-card-button-wrapper {
    margin-top: 16px;
    border-top: 1px solid var(--tq-black-70);
    display: flex;
    gap: 8px;
    padding-top: 8px;
  }
}

.textarea {
  max-height: none;
}

.form-title {
  .display-h4;
}

.form-btns {
  .ant-btn {
    width: 48%;
  }
}

.form-block {
  .bg-n-200;
  box-shadow: @tq-shadow-m;
  max-width: 576px;
  margin: auto;
  border-radius: 16px;
  overflow: hidden;

  @media @screen767 {
    box-shadow: unset;
    max-width: 100%;
    border-radius: 0;
    .bg-n-300;
  }

  .cs-devider {
    max-width: 351px;
    margin: 24px auto;

    @media @screen767 {
      max-width: 240px;
      margin: 16px auto;
    }
  }

  .d-title,
  .d-dec {
    display: none;
  }

  .container {
    padding: 0;
  }

  h1 {
    .display-h1;
    text-align: center;
    margin: 16px 0 32px 0;

    @media @screen767 {
      margin: 0 0 24px 0;
    }
  }

  .form-container {
    .bg-blue-2;
    .text-black-90;
    padding: 24px 16px 16px 16px;
    text-align: center;

    @media @screen767 {
      padding: 24px 16px;
      padding-bottom: 18px;
    }

    .d-price {
      .text-black-90;
      @media @screen767 {
        margin-bottom: 6px;
      }
    }

    .title {
      .display-h4;
      margin-bottom: 20px;
    }

    .ant-radio-wrapper .ant-radio-inner {
      background: transparent;
    }

    .ant-radio + span {
      .text-small;

      @media @screen767 {
        .text-md;
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .form-module-body {
    padding: 32px 48px;

    @media @screen767 {
      padding: 24px 16px 0 16px;
    }

    .form-group {
      margin-bottom: 24px;

      &.margin-16 {
        margin-bottom: 16px;
      }

      @media @screen767 {
        margin-bottom: 20px;
      }

      .ant-form-item-extra {
        margin-top: 2px;
      }
    }

    .donate-submit-btn {
      margin-top: 8px;

      @media @screen767 {
        margin-top: 4px;
      }
    }

    .cs-accordion {
      margin-top: 32px;
    }
  }
}

.article-v2 {
  padding: 40px 20px;
  background: var(--tq-blue);
  .container {
    max-width: 1020px;
  }
  h2 {
    font: var(--tq-display-h3);
    font-family: var(--tq-primary-font);
    font-weight: 600;
    margin-bottom: 32px;
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      padding: 24px 0;
      border-bottom: 1px solid var(--tq-neutral-800);
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
      .heading {
        display: flex;
        align-items: baseline;
        gap: 6px;
        color: var(--tq-neutral-800);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
      }
      svg {
        flex-shrink: 0;
      }
    }
  }
}

.featured-article-card {
  .heading {
    display: flex;
    align-items: baseline;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    gap: 6px;
    margin-bottom: 24px;
    @media @screen767 {
      margin-bottom: 16px;
    }
  }
  .cms-content {
    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.32px;
      margin-bottom: 20px;
      @media @screen767 {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.32px;
      text-decoration-line: underline;
      margin-top: 16px;
      @media @screen767 {
        font-size: 16px;
      }
    }
  }
}
.related-reading {
  background: #f4f6f8;
  padding: 32px 0;
  .container {
    max-width: 1020px;
  }
  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    margin-bottom: 32px;
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      padding: 24px 0;
      border-bottom: 1px solid #d9d9d9;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
      .heading {
        display: flex;
        align-items: baseline;
        gap: 6px;
        color: var(--primary);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
      }
      svg {
        flex-shrink: 0;
      }
    }
  }
}

.moderation-posts {
  .ant-col {
    width: 100%;
  }
  .post-card {
    padding: 16px;
    border: 1px solid @border-color;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    .post-card-body {
      flex-grow: 1;

      .post-instance {
        .mb-8;
      }
    }

    .created-at {
      .text-black-70;
      .text-tiny;
      align-self: self-start;
    }
  }
}

.attachment {
  .mb-8;
  .image-wrapper {
    .ant-image {
      aspect-ratio: 16 / 9;
      .ant-image-img {
        object-fit: contain;
      }
    }
  }
  .video-player-wrapper {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  .audio-player-wrapper {
    // used important here to override inline css
    height: 74px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;

    audio {
      height: 54px !important;
    }
  }
}

.stats-wrapper {
  padding: 8px 0px;
  border-top: 1px solid @border-color;
  border-bottom: 1px solid @border-color;

  .count-wrapper {
    a:hover {
      color: #1677ff;
    }
    svg {
      flex-shrink: 0;
      height: 16px;
      width: 16px;
    }
  }
}

.moderation-tag {
  border-radius: 999px;
  padding: 2px 8px;
  font-size: 12px;
  &.danger {
    background-color: #f5222d;
    color: #ffffff;
  }
}

.preview-frame {
  width: 100%;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  flex-grow: 1;
}

.nested-comments-container {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  overflow-x: auto;

  .comments-container {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    max-width: 780px;
    min-width: 780px;
    flex-shrink: 0;
    overflow-x: hidden;

    .filters-wrapper {
      padding: 26px 26px 0 26px;

      @media @screen767 {
        padding: 16px 16px 0 16px;
      }
    }

    .ant-body-scroll {
      overflow: hidden;
    }

    .comments {
      .comment-card {
        padding: 16px;
        outline: 1px solid @border-color;
        border-radius: 8px;
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        transition: outline 0.2s ease-in;

        &.selected,
        &:hover {
          outline: 2px solid @black-light;
        }

        .comment-card-body {
          flex-grow: 1;

          .comment-text {
            white-space: pre-line;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .created-at {
          .text-black-70;
          .text-tiny;
          align-self: self-start;
        }
      }
    }
  }
}

.row-reverse {
  flex-direction: row-reverse;
}

.dashboard-logo {
  width: 200px;
  margin-left: 24px;
}
